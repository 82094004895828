<template>
	<div class="page mine_page">
		<div class="w1000">
			<topHeader :isShowBack="false"></topHeader>
			<div class="block20"></div>
			<!-- 内容 -->
			<div class="mine_conbox" v-if="formobj">
				<div class="conbox1">
					<div class="itemobj1">
						<img class="user-head" :src="formobj.hlImage || require('../assets/logo.png')"/>
						<div class="text" @click="getedittype(true)">用户头像</div>
						<img class="right-icon" @click="getedittype(true)" src="../assets/static/Group6.png"></image>
					</div>
					<div class="itemobj1 itemobj2">
						<div class="text1">用户昵称</div>
						<div class="text2" @click="getedittype(true)">{{formobj.nikename || '修改昵称'}}</div>
						<img class="right-icon" @click="getedittype(true)" src="../assets/static/Group6.png"></image>
					</div>
					<div class="itemobj1 itemobj2">
						<div class="text1">会员身份</div>
						<div class="text2">{{vipTpyes[formobj.level]}}</div>
						<div class="right-icon"></div>
					</div>
				</div>
				<div class="block30"></div>
				<div class="conbox1">
					<div class="itemobj1 itemobj2">
						<div class="text1">性别</div>
						<div class="text2" @click="getedittype(true)">{{formobj.gender || '去设置'}}</div>
						<img class="right-icon" @click="getedittype(true)" src="../assets/static/Group6.png"></image>
					</div>
					<div class="itemobj1 itemobj2">
						<div class="text1">年龄(岁)</div>
						<div class="text2" @click="getedittype(true)">{{formobj.age || '去设置'}}</div>
						<img class="right-icon" @click="getedittype(true)" src="../assets/static/Group6.png"></image>
					</div>
					<div class="itemobj1 itemobj2">
						<div class="text1">年级阶段</div>
						<div class="text2" @click="getedittype(true)">{{getclassLevel(formobj.stageId)}}</div>
						<img class="right-icon" @click="getedittype(true)" src="../assets/static/Group6.png"></image>
					</div>
				</div>
			</div>
		</div>
		<!--  查看或编辑    -->
		<el-dialog title="查看或编辑" :visible.sync="dialogFormVisible" v-if="editobj">
			<el-form ref="form" :model="editobj" :label-width="formLabelWidth">
				<el-form-item label="用户昵称">
					<el-input v-model="editobj.nikename" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="用户头像">
					<el-upload class="el-avatar" action="" :show-file-list="false" :http-request="handleimageUrl" :before-upload="beforeAvatarUpload">
						<img v-if="editobj.hlImage" :src="editobj.hlImage" class="avatar" />
						<i v-else class="el-icon-plus el-avatar" />
					</el-upload>
					<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1M，宽高比是1:1,建议100x100</div>
				</el-form-item>
				<el-form-item label="性别">
					<el-select v-model="editobj.gender">
						<el-option label="男" value="男" />
						<el-option label="女" value="女" />
					</el-select>
				</el-form-item>
				<el-form-item label="年龄(岁)">
					<el-input v-model="editobj.age" placeholder="请输入数字" type="number"/>
				</el-form-item>
				<el-form-item label="年级阶段">
					<el-select v-model="editobj.stageId">
						<el-option v-for="(item, index) in classLevel" :key="index" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item style="margin-top: 20px">
					<el-button type="primary" @click="edithlUser()">保存修改信息</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import topHeader from "@/components/topHeader/topHeader.vue";
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
export default {
	components:{
		topHeader
	},
	data() {
		return {
			formLabelWidth:"128px",
			formobj:null,
			editobj:{},
			dialogFormVisible:false,
		};
	},
	mounted: function() {
		console.log(this.userInfo)
		this.formobj = this.userInfo
	},
	computed: {
		...mapState(['userInfo','classLevel','vipTpyes']),
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//修改
		edithlUser() {
			var _this = this
			var params = this.editobj
			showLoading()
			this.$http.post('app/hlexam/hlUser/edit', params).then(function(res) {
				//数据处理
				hideLoading()
				if (res.code == 200) {
					_this.SET_USERINFO(res.data)
					//保存
					//保存
					sessionStorage.setItem('key_state',JSON.stringify(_this.$store.state))
					_this.$message.success("修改信息成功！")
					_this.getedittype(false)
					_this.formobj = _this.userInfo
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		// 上传图片--新增
		async handleimageUrl({
			file
		}) {
			var res = await this.$api.uploadHttp(file, {})
			if (res && res.status == 200) {
				this.editobj.hlImage = res.requestUrls[0]
				this.$message.success('上传图片成功!')
				//this.edithlUser()
			} else {
				this.$message.error('上传图失败!')
			}
		},
		beforeAvatarUpload(file) {
			// console.log(file)
			const isLt10M = file.size / 1024 / 1024 < 5
			if (!isLt10M) {
				this.$message.error('上传头像图片大小不能超过 5MB!')
				return false
			}
			if (file.type == 'image/jpeg' || file.type == 'image/png') {
				console.log(this.BASEURL)
			} else {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
			}
		},
		//打开修改
		getedittype(type){
			if(type){
				this.editobj = JSON.parse(JSON.stringify(this.formobj))
			}
			this.dialogFormVisible = type
		},
		getclassLevel(stageId){
			if(stageId){
				const obj = this.classLevel.find((ifitem) => { //这里的options就是上面遍历的数据源
					return ifitem.id == stageId //筛选出匹配数据
				});
				if(obj){
					return obj.title;
				}else{
					return stageId
				}
			}else{
				return '设置'
			}
		},
		
	}
};
</script>
<style lang="scss" scoped>
	.mine_conbox{
		width: 100%;
		.conbox1{
			width: 100%;
			padding: 20px 40px;
			box-sizing: border-box;
			border-radius: 16px;
			border: 2px solid #E5E5E5;
			.itemobj1{
				width: 100%;
				padding: 20px 0;
				display: flex;
				align-items: center;
				.user-head{
					width: 60px;
					height: 60px;
					border-radius: 50%;
					background-color: #f8f8f8;
				}
				.text{
					margin-left: 18px;
					color: #222;
					font-size: 16px;
					cursor: pointer;
				}
				.right-icon{
					width: 20px;
					height: 20px;
					margin-left: 8px;
					cursor: pointer;
				}
			}
			.itemobj1:not(:last-child){
				border-bottom: 1px solid #E0E0E0;
			}
			.itemobj2{
				.text1{
					color: #666;
					font-size: 16px;
				}
				.text2{
					margin-left: auto;
					color: #222;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
	}
</style>
